import { absolutePath } from "./absolutePath";

interface CommunityBotFnProps {
  id?: string | number;
  params?: Record<string, unknown>;
}

interface CustomResourceProgressParams extends Record<string, unknown> {
  snippetId: string | number;
  community_bot_profile_id: string | number;
}

interface CommunityResourceProgressParams extends Record<string, unknown> {
  spaceId: string | number;
  community_bot_profile_id: string | number;
}

const emptyArgs = {
  params: {},
};

export const communityBot = {
  communityResources: {
    index: ({ params = {} }: CommunityBotFnProps = {}) =>
      absolutePath("community_bot/community_resources", params),
    import: ({ params = {} }: CommunityBotFnProps = {}) =>
      absolutePath("community_bot/community_resources/bulk_import", params),
    destroy: ({ params = {} }: CommunityBotFnProps = {}) =>
      absolutePath("community_bot/community_resources/bulk_destroy", params),
    progress: ({ params }: { params: CommunityResourceProgressParams }) =>
      absolutePath(
        `community_bot/community_resources/${params.spaceId}/progress`,
        { community_bot_profile_id: params.community_bot_profile_id },
      ),
  },
  customResources: {
    index: ({ params = {} }: CommunityBotFnProps = {}) =>
      absolutePath("community_bot/custom_resources", params),
    import: ({ params = {} }: CommunityBotFnProps = {}) =>
      absolutePath("community_bot/custom_resources/bulk_import", params),
    destroy: ({ params = {} }: CommunityBotFnProps = {}) =>
      absolutePath("community_bot/custom_resources/bulk_destroy", params),
    progress: ({ params }: { params: CustomResourceProgressParams }) =>
      absolutePath(
        `community_bot/custom_resources/${params.snippetId}/progress`,
        { community_bot_profile_id: params.community_bot_profile_id },
      ),
  },
  snippets: {
    index: ({ params = {} }: CommunityBotFnProps = {}) =>
      absolutePath("community_bot/snippets", params),
    show: ({ id }: CommunityBotFnProps = {}) =>
      absolutePath(`community_bot/snippets/${id}`),
    create: ({ params }: CommunityBotFnProps = emptyArgs) =>
      absolutePath(`community_bot/snippets`, params),
    createFromFiles: ({ params }: CommunityBotFnProps = emptyArgs) =>
      absolutePath(`community_bot/snippets/create_from_files`, params),
    update: ({ id }: CommunityBotFnProps = {}) =>
      absolutePath(`community_bot/snippets/${id}`),
    destroy: ({ id }: CommunityBotFnProps = {}) =>
      absolutePath(`community_bot/snippets/${id}`),
    bulkDestroy: ({ params = {} }: CommunityBotFnProps = {}) =>
      absolutePath(`community_bot/snippets/bulk_destroy`, params),
  },
  agents: {
    index: ({ params = {} }: CommunityBotFnProps = {}) =>
      absolutePath("community_bot/profiles", params),
    show: ({ id }: CommunityBotFnProps = {}) =>
      absolutePath(`community_bot/profiles/${id}`),
    create: () => absolutePath(`community_bot/profiles`),
    update: ({ id }: CommunityBotFnProps = {}) =>
      absolutePath(`community_bot/profiles/${id}`),
    audienceCount: ({ params = {} } = {}) =>
      absolutePath("community_bot/profiles/audience_count", params),
    indexForSearch: ({ params = {} }: CommunityBotFnProps = {}) =>
      absolutePath("search/community_bot/profiles/index_for_search", params),
  },
  spaces: {
    index: ({ params = {} }: CommunityBotFnProps = {}) =>
      absolutePath("community_bot/spaces", params),
    update: ({ id }: CommunityBotFnProps = {}) =>
      absolutePath(`community_bot/spaces/${id}`),
  },
  setting: {
    show: () => absolutePath("community_bot/setting"),
    update: () => absolutePath("community_bot/setting"),
  },
};
