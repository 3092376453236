import type { ReactNode } from "react";
import { createContext, useContext, useEffect, useState } from "react";
import { find } from "lodash";
import { getIsLocalStorageCacheEnabled } from "@/react/helpers/communityHelpers";
import { useLocalStorage } from "@/react/hooks/utils/useLocalStorage";
import { usePunditUserContext } from "@circle-react/contexts";
import type { Space } from "@circle-react/types/Space";
import type { IndexResponseData } from "../api";
import { spaceApi } from "../api";
import {
  canCreateEventInSpace,
  canCreatePostInBasicOrImageSpace,
  canCreatePostInBasicSpace,
  canCreatePostInImageSpace,
  canCreatePostInSpace,
  canViewSpaceContent,
} from "../helpers/spaceHelpers";
import type { LoadingStatus } from "./loadingStatuses";
import { LOADED, LOADING, REFRESHING } from "./loadingStatuses";

const findSpace = (records: Space[], conditions: object) => {
  const result = find(records, conditions);
  if (result && typeof result === "object" && "id" in result) {
    return result;
  }
  return undefined;
};

export interface SpaceContextType {
  status: LoadingStatus;
  records: Space[];
  sidebarHtml: string;
  actions: {
    loadData: () => Promise<void>;
    refresh: () => Promise<void>;
    updateSpace: (newSpace: Space) => void;
  };
  isLoading: boolean;
  helpers: {
    isDataLoading: () => boolean;
    isDataRefreshing: () => boolean;
    isDataLoaded: () => boolean;
    findById: (id: number) => Space | undefined;
    findBy: (conditions: object) => Space | undefined;
    spacesWithPostCreationAllowed: () => Space[];
    spacesWithEventCreationAllowed: () => Space[];
    basicSpacesWithPostCreationAllowed: () => Space[];
    basicOrImageSpacesWithPostCreationAllowed: () => Space[];
    imageSpacesWithPostCreationAllowed: () => Space[];
    spaceVisibleOnSidebar: () => Space[];
    spaceWithViewContentAccess: () => Space[];
  };
}

const SpacesContext = createContext<SpaceContextType | null>(null);
SpacesContext.displayName = "SpacesContext";

export interface SpacesContextProviderProps {
  children: ReactNode;
  includeSidebar?: boolean;
  spaces?: Space[];
}

export const SpacesContextProvider = ({
  children,
  includeSidebar,
  spaces,
}: SpacesContextProviderProps) => {
  const [status, setStatus] = useState<LoadingStatus>(LOADING);
  const [records, setRecords] = useState<Space[]>([]);
  const [sidebarHtml, setSidebarHtml] = useState("");
  const { currentCommunityMember } = usePunditUserContext();
  const { getValue: getLocalStorageValue, setValue: setLocalStorageValue } =
    useLocalStorage<Space[]>("SpacesContextProvider");

  const onDataLoadSuccess = (data: IndexResponseData) => {
    setRecords(data.records);
    setLocalStorageValue(data.records);
    setSidebarHtml(data.sidebar_html);
    setStatus(LOADED);
  };

  useEffect(() => {
    if (spaces) {
      setRecords(spaces);
      setStatus(LOADED);
    } else {
      if (getIsLocalStorageCacheEnabled()) {
        const localStorageSpaces = getLocalStorageValue();
        if (localStorageSpaces && localStorageSpaces.length > 0) {
          setRecords(localStorageSpaces);
          setStatus(LOADED);
        }
      }

      void loadData();
    }
  }, []);

  const loadData = async () => {
    const response = await spaceApi.index({
      params: { include_sidebar: !!includeSidebar },
    });
    if (response.ok) {
      const data = (await response.json()) as IndexResponseData;
      onDataLoadSuccess(data);
    } else {
      // Add error handling. May be show toast message
    }
  };

  const updateSpace = (newSpace: Space) => {
    if (isDataLoaded()) {
      setRecords(
        records.map(space => (newSpace.id == space.id ? newSpace : space)),
      );
    } else {
      throw new Error("updateSpace called before data was loaded");
    }
  };

  const refresh = () => {
    setStatus(REFRESHING);
    return loadData();
  };

  const isDataLoaded = () => status === LOADED;
  const isDataLoading = () => status === LOADING;
  const isDataRefreshing = () => status === REFRESHING;

  const findById = (id: number) => findBy({ id });

  const findBy = (conditions = {}) => {
    if (!isDataLoaded()) {
      return undefined;
    }
    return findSpace(records, conditions);
  };

  const spaceWithViewContentAccess = () =>
    records.filter((space: Space) =>
      canViewSpaceContent(space, currentCommunityMember),
    );

  const spacesWithPostCreationAllowed = () =>
    records.filter(canCreatePostInSpace);

  const spacesWithEventCreationAllowed = () =>
    records.filter(canCreateEventInSpace);

  const basicSpacesWithPostCreationAllowed = () =>
    records.filter(canCreatePostInBasicSpace);

  const basicOrImageSpacesWithPostCreationAllowed = () =>
    records.filter(canCreatePostInBasicOrImageSpace);

  const imageSpacesWithPostCreationAllowed = () =>
    records.filter(canCreatePostInImageSpace);

  const spaceVisibleOnSidebar = () => records;

  const value = {
    status,
    records,
    sidebarHtml,
    actions: { loadData, refresh, updateSpace },
    isLoading: isDataLoading() || isDataRefreshing(),
    helpers: {
      isDataLoading,
      isDataRefreshing,
      isDataLoaded,
      findById,
      findBy,
      spacesWithPostCreationAllowed,
      spacesWithEventCreationAllowed,
      basicSpacesWithPostCreationAllowed,
      imageSpacesWithPostCreationAllowed,
      basicOrImageSpacesWithPostCreationAllowed,
      spaceVisibleOnSidebar,
      spaceWithViewContentAccess,
    },
  };
  return (
    <SpacesContext.Provider value={value}>{children}</SpacesContext.Provider>
  );
};

export const useSpacesContext = (): SpaceContextType => {
  const context = useContext(SpacesContext);
  if (!context) {
    throw new Error(
      "useSpacesContext must be used within a SpacesContextProvider",
    );
  }
  return context;
};
